.logo {
    font-size: 25px;
    font-family: "Monument";
    font-weight: 1000;
    padding-top: 20px;
}

.nav-main {
    width: 100%;
}

.nav {
    padding-top: 27px;
}

.nav-item {
    float: right;
    vertical-align: middle;
    font-family: "Monument";
    color: black;
    text-align: center;
    padding-right: 10px;
    letter-spacing: 1px;
}

.list {
    padding-top: 2px;
    list-style-type: none;
    overflow: hidden;
    font-weight: bolder;
}

.nav-item:hover {
    color: #84b8b2;
    text-decoration: none;
    cursor: pointer;
}
