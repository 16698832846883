.home-container {
    padding-top: 10vh!important;
    padding-bottom: 10vh!important;
    padding-right: 0!important;
    padding-left: 0!important;
    transition: all 2s;
    z-index: 0;
}

.extended {
    padding-top: 10vh!important;
    padding-bottom: 10vh!important;
    padding-right: 0!important;
    padding-left: 0!important;
    transition: all 2s ;
    background-color: #84b8b2;
    z-index: 0;
}

.home-title {
    font-size: min(calc(20px + 7vw),130px);
    font-weight: bold;
    font-family: "Monument";
    padding-top: 20vh;
    text-align: center;
    visibility: visible;
}

.home-text {
    font-size: min(20px, calc(5px + 2.5vw));
    font-family: "Monument";
    color:#e2f5e4;
    padding-bottom: 14vh;
    padding-left: 4vw;
    padding-right: 4vw;
    z-index: 1;
}

#wrapper {
    line-height: max(min(4vw,200%),175%);
    user-select: none;
}