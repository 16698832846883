.skills-title {
    font-size: min(calc(13px + 7vw), 65px);
    font-weight: bold;
    font-family: 'Monument';
    margin-top: 80px;
    margin-bottom: -1.5vh;
    padding-right: 2vw;
    text-align: right;
    line-height: 0.55em;
    width: 100%;
}

.subtitles {
    font-family: 'Monument';
    font-size: min(20px, calc(5px + 2.5vw));
    padding-top: 20px;
    padding-left: 2vw;
    padding-right: 2vw;
}

.skills-list {
    font-size: min(17px, calc(5px + 2vw));
    line-height: max(min(4vw,200%),175%);
    color:#e2f5e4;
    list-style-type: none;
    padding-right: 25vw;
    padding-bottom: 50px;
}

.skills-text {
    font-size: min(17px, calc(5px + 2vw));
    color:#e2f5e4;
    padding-right: 2vw;
    padding-left: 2vw;
    line-height: max(min(4vw,200%),175%);
}