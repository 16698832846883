.contact-title {
    font-size: min(calc(13px + 7vw), 65px);
    font-weight: bold;
    font-family: 'Monument';
    margin-top: 80px;
    margin-bottom: -1.5vh;
    padding-right: 2vw;
    text-align: center;
    line-height: 0.55em;
    width: 100%;
}

.contact-subtitle {
    font-family: 'Monument';
    padding-left: 2vw;
}

.font-size1 {
    font-size: min(10px, calc(2.5px + 1.25vw));
    padding-top: 5px;
}

.font-size2{
    font-size: min(20px, calc(5px + 2.5vw));
}

.back-to-top {
    font-family: "Monument";
    font-size: min(20px, calc(5px + 2.5vw));
    color: black;
    text-align: center;
}

.back-to-top:hover {
    text-decoration: none;
    color:#e2f5e4;
}

.links {
    color: black;
}

.links:hover {
    text-decoration: none;
    color:#e2f5e4;
}