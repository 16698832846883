.projects-title {
    font-size: min(calc(13px + 7vw), 65px);
    font-weight: bold;
    font-family: 'Monument';
    padding-left: 4vw;
    writing-mode: vertical-rl ;
}

.projects-subtitle {
    font-family: 'Monument';
    font-size: min(20px, calc(5px + 2.5vw));
    padding-bottom: 20px;
    padding-right: 2vw;
}

.projects-text {
    font-size: min(17px, calc(5px + 2vw));
    line-height: max(min(4vw,200%),175%);
    color:#e2f5e4;
}